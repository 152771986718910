import * as React from "react"
import { useState, useEffect } from "react"
import styled from "styled-components"
import axios from "axios"
import devices from "styles/devices"
import { LenderIcon } from "../LenderIcon"
import { ToCurrency } from "lib/CurrencyFormatter"
import { formatAppointmentDate } from "lib/FormatDate"
import { RateCheckBody, ILRState } from "../types"
import { Loader, LoaderTheme } from "components/atoms/Loader"
import {
  RateCheckPromiseHero,
  DisclaimerFooter,
  HowItWorksRateCheckPromise,
  RateCheckPromiseFaqs,
} from "../../../../RateCheckPromise"
import { RateCard } from "./RateCard"
import { Notifications } from "components/atoms/Notifications"
import toast from "react-hot-toast/headless"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"
import { Button } from "components/atoms"
import {
  desktop,
  desktopContentPadding,
  mobile,
  space,
  tablet,
  tabletContentPadding,
} from "components/_blueprint/Layout"
import { SummaryDetails } from "./SummaryDetails"
import { MY_MOJO_API } from "../../../../../Config"
import { useParams } from "react-router-dom"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import { LogError } from "logging"
import { useSession } from "lib/auth/react"

export const RateCheckerPage = ({ applicationId }: { applicationId: string }) => {
  const { friendlyId } = useParams<{ friendlyId: string }>()
  const { isLoading: sessionLoading } = useSession()
  const [rateCheckerData, setRateCheckerData] = useState<RateCheckBody | null>(null)
  const [actionClicked, setActionClicked] = useState<"current" | "new" | null>(null)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  useTrackNavigatedToPage("/application/rate-check-promise")

  useEffect(() => {
    if (applicationId && !sessionLoading) {
      axios
        .get(`${process.env.MY_MOJO_API}/rate-check/${applicationId}/v2`, {
          withCredentials: true,
        })
        .then(({ data }) => {
          setRateCheckerData(data?.data)
          setIsError(false)
          setIsLoading(false)
        })
        .catch((error) => {
          console.error(error)
          setIsError(true)
          setIsLoading(false)
        })
    }
  }, [applicationId, sessionLoading])

  const { rateCheck = null, identifiedLowerRate = null } = rateCheckerData || {}
  const {
    LenderName,
    Cashback,
    FeesTotal,
    FreeLegalFees,
    ValuationFee,
    TrueCostOverInitialPeriod,
    InitialMonthlyPayment,
    InitialMonthlyPaymentIncludingFees,
  } = rateCheck?.customerProductDetails || {}

  const { loanAmount, mortgageClass, propertyValue, termMonths, addFeesToLoan } =
    rateCheck?.productInput || {}

  const ilrMonthlyPayment = addFeesToLoan
    ? ToCurrency(identifiedLowerRate?.productDetails?.InitialMonthlyPaymentIncludingFees)
    : ToCurrency(identifiedLowerRate?.productDetails?.InitialMonthlyPayment)

  const monthlyPayment = addFeesToLoan
    ? ToCurrency(InitialMonthlyPaymentIncludingFees)
    : ToCurrency(InitialMonthlyPayment)
  const formattedCashback = ToCurrency(Cashback)
  const formattedTotalLoanAmount = ToCurrency(loanAmount)
  const formattedPropertyValue = ToCurrency(propertyValue)
  const formattedRateCheckDate = formatAppointmentDate(new Date(rateCheck?.lastChecked))
  const formattedFeesTotal = ToCurrency(FeesTotal)
  const termYears = Math.floor(termMonths / 12)
  const active =
    identifiedLowerRate?.status === ILRState.Found ||
    identifiedLowerRate?.status === ILRState.Progressing
  const totalSavings =
    TrueCostOverInitialPeriod - identifiedLowerRate?.productDetails?.TrueCostOverInitialPeriod
  const totalSavingsOverCurrRate =
    rateCheck?.customerProductDetails?.TrueCostOverInitialPeriod - TrueCostOverInitialPeriod
  const totalMonthlySavings = monthlyPayment - ilrMonthlyPayment
  const formattedTotalSavings = ToCurrency(totalSavings)
  const formattedTotalMonthlySavings = ToCurrency(totalMonthlySavings)

  const productLTV = Math.floor((loanAmount / propertyValue) * 100)

  const compareValues = (currentValue: number | string, newValue: number | string): ArrowColor => {
    return newValue < currentValue ? "green" : newValue > currentValue ? "orange" : null
  }

  const compareDates = (currentValue: string, newValue: string): ArrowColor => {
    const currentValueMs = new Date(currentValue).getTime()
    const valueMs = new Date(newValue).getTime()
    return valueMs > currentValueMs ? "orange" : null
  }

  const arrows = {
    initialRate: compareValues(
      rateCheckerData?.rateCheck?.customerProductDetails.InitialPayRate,
      identifiedLowerRate?.productDetails.InitialPayRate
    ),
    monthlyPayment: compareValues(monthlyPayment, ilrMonthlyPayment),
    overallCost: compareValues(
      rateCheckerData?.rateCheck?.customerProductDetails.TrueCostOverInitialPeriod,
      identifiedLowerRate?.productDetails.TrueCostOverInitialPeriod
    ),
    aprc: compareValues(
      rateCheckerData?.rateCheck?.customerProductDetails.AprLenders,
      identifiedLowerRate?.productDetails.AprLenders
    ),
    productFee: compareValues(
      rateCheckerData?.rateCheck?.customerProductDetails.ArrangementFee,
      identifiedLowerRate?.productDetails.ArrangementFee
    ),
    endDate: compareDates(
      rateCheckerData?.rateCheck?.customerProductDetails.InitialRatePeriodPrecise,
      identifiedLowerRate?.productDetails.InitialRatePeriodPrecise
    ),
  }

  useEffect(() => {
    if (actionClicked === "current") {
      toast(
        <div>
          <div>
            <b>Thanks for letting us know</b>
          </div>
          <div>
            You’ll stay on your current rate and we’ll keep you updated if the rate goes down again
          </div>
        </div>,
        { id: "confirmed" }
      )
    }
    if (actionClicked === "new") {
      toast(
        <div>
          <div>
            <b>Thanks for letting us know</b>
          </div>
          <div>
            We’ll submit your request and update you as soon as we hear back from the lender
          </div>
        </div>,
        { id: "confirmed" }
      )
    }
  }, [actionClicked])

  return (
    <ContentGrid>
      <ContentContainer>
        {isLoading ? (
          <LoaderContainer>
            <Loader size={150} theme={LoaderTheme.Outline} />
          </LoaderContainer>
        ) : (
          <>
            {rateCheck ? (
              <>
                <HeaderContainer>
                  <TitleContainer>
                    <Header>Rate Check Promise</Header>
                    {rateCheck.lastChecked && (
                      <SummaryCheckDateDesktop>
                        Last rate check:<span>{formattedRateCheckDate}</span>
                      </SummaryCheckDateDesktop>
                    )}
                  </TitleContainer>
                  {identifiedLowerRate?.status === ILRState.Found && (
                    <TextContainer>
                      <Subheader>We’ve found you a lower rate</Subheader>
                      <p>
                        Great news! Your chosen lender {LenderName} has lowered their interest
                        rates. You can review the new rate below and if you’d like to switch, simply
                        request it below. We’ll begin the process as long as there’s time before
                        your completion date.
                      </p>
                    </TextContainer>
                  )}
                  {identifiedLowerRate?.status === ILRState.Progressing && (
                    <>
                      <Subheader>We’re requesting your new interest rate</Subheader>
                      <p>
                        Thanks! We’ll contact {LenderName} to request the switch to your new rate.
                        Please note, lenders have varying timescales and if you are too close to
                        completion, it may not be approved. We’ll update you once it’s confirmed.
                      </p>
                    </>
                  )}
                  {identifiedLowerRate?.status === ILRState.Declined && (
                    <TextContainer>
                      <Subheader>You’re staying with your current rate</Subheader>
                      <p>
                        Thank you for confirming your choice. We’ll keep you on your current rate.
                        We’ll continue to monitor the market and update you again if there is a
                        lower rate with {LenderName} available.
                      </p>
                    </TextContainer>
                  )}
                  {(identifiedLowerRate?.status === ILRState.Cancelled ||
                    identifiedLowerRate?.status === ILRState.Expired) && (
                    <>
                      <Subheader>We’re monitoring for lower rates</Subheader>
                      <p>
                        We’re still keeping any eye on the {LenderName}’s rates, and will update you
                        if they lower. Right now, you’re still on the lowest rate for your chosen
                        deal with {LenderName}.
                      </p>
                    </>
                  )}
                  {identifiedLowerRate?.status === ILRState.Completed && (
                    <>
                      <Subheader>You’ve moved onto a lower rate</Subheader>
                      <p>
                        Great news! {LenderName} have approved the move onto the lower rate, meaning
                        you’ve just saved {ToCurrency(totalSavingsOverCurrRate)} in interest over
                        the initial period of your mortgage.
                      </p>
                    </>
                  )}
                  {!identifiedLowerRate && (
                    <>
                      <Subheader>Monitoring for lower rates</Subheader>
                      <p>
                        Our Rate Check Promise technology reviews the market daily for lower rates
                        with the lender you have applied with. If we find a lower rate, and can
                        switch you before your completion date, we’ll notify you immediately.
                      </p>
                    </>
                  )}
                  <SummaryDetails
                    formattedTotalLoanAmount={formattedTotalLoanAmount}
                    termYears={termYears}
                    formattedFeesTotal={formattedFeesTotal}
                    formattedPropertyValue={formattedPropertyValue}
                    productLTV={productLTV}
                    addFeesToLoan={addFeesToLoan}
                  />
                </HeaderContainer>
                <RatesWrapper>
                  <SummaryWrapper>
                    <SummaryProductDetails>
                      <ProductDetailsWrapper>
                        <SummaryIcon>
                          <LenderIcon lenderName={LenderName} />
                        </SummaryIcon>
                        <ProductExtrasWrapper>
                          <ProductExtras>
                            <ExtrasList aria-label="Extras">
                              {
                                <ExtraPill>
                                  <span>{mortgageClass}</span>
                                </ExtraPill>
                              }
                              {Cashback > 0 && (
                                <ExtraPill>
                                  <span>{formattedCashback} Cashback</span>
                                </ExtraPill>
                              )}
                              {FreeLegalFees && (
                                <ExtraPill>
                                  <span>Free standard legal costs</span>
                                </ExtraPill>
                              )}
                              {ValuationFee === 0 && (
                                <ExtraPill>
                                  <span>Free valuation</span>
                                </ExtraPill>
                              )}
                            </ExtrasList>
                          </ProductExtras>
                        </ProductExtrasWrapper>
                      </ProductDetailsWrapper>
                    </SummaryProductDetails>
                    {rateCheck.lastChecked && (
                      <SummaryCheckDateMobile>
                        Last rate check:<span>{formattedRateCheckDate}</span>
                      </SummaryCheckDateMobile>
                    )}
                    {identifiedLowerRate?.status === ILRState.Progressing &&
                      totalMonthlySavings > 0 &&
                      totalSavings > 0 && (
                        <SummaryMessage>
                          This new deal would <span>save you {formattedTotalMonthlySavings}</span> a
                          month. You’d <span>save {formattedTotalSavings} in interest</span> over
                          the fixed period, compared to your current rate.
                        </SummaryMessage>
                      )}
                    {identifiedLowerRate?.status === ILRState.Expired && (
                      <AlertSummaryMessage>
                        Unfortunately this new rate is no longer available. We will continue
                        monitoring the rates from {LenderName} and will notify you by email and SMS
                        if rates lower again.
                      </AlertSummaryMessage>
                    )}
                    {identifiedLowerRate?.status === ILRState.Cancelled && (
                      <AlertSummaryMessage>
                        Unfortunately your application for the new rate with {LenderName} has been
                        declined. Your Case Manager will be in contact with you to provide you with
                        more detail.
                      </AlertSummaryMessage>
                    )}
                  </SummaryWrapper>
                  <RatesContainer>
                    <RateCard
                      actionClicked={actionClicked}
                      currentRate={true}
                      identifiedLowerRateId={identifiedLowerRate?.id}
                      identifiedLowerRateStatus={identifiedLowerRate?.status}
                      isError={isError}
                      productDetails={
                        identifiedLowerRate?.status === ILRState.Completed
                          ? identifiedLowerRate?.productDetails // if the ILR was successfully completed, show the new rate as the current rate
                          : rateCheckerData?.rateCheck?.customerProductDetails // if the ILR was successfully completed, show the new rate as the current rate
                      }
                      productInput={rateCheckerData?.rateCheck?.productInput}
                      setIsError={setIsError}
                      setActionClicked={setActionClicked}
                      handleEsisDownload={() => {
                        handleEsisDownloadCurrRate(applicationId)
                      }}
                      arrows={arrows}
                    />
                    {isLoading ? (
                      <LoaderContainer>
                        <Loader size={96} theme={LoaderTheme.Outline} />
                      </LoaderContainer>
                    ) : (
                      <RateCard
                        actionClicked={actionClicked}
                        currentRate={false}
                        identifiedLowerRate={identifiedLowerRate}
                        identifiedLowerRateId={identifiedLowerRate?.id}
                        identifiedLowerRateStatus={identifiedLowerRate?.status}
                        isError={isError}
                        productDetails={identifiedLowerRate?.productDetails}
                        productInput={rateCheckerData?.rateCheck?.productInput}
                        setActionClicked={setActionClicked}
                        setIsError={setIsError}
                        handleEsisDownload={() => {
                          handleEsisDownloadNewRate(
                            applicationId,
                            friendlyId,
                            identifiedLowerRate?.id
                          )
                        }}
                        arrows={arrows}
                      />
                    )}
                  </RatesContainer>
                </RatesWrapper>
                <>
                  <HowItWorksRateCheckPromise />
                  <RateCheckPromiseFaqs />
                  <DisclaimerFooter />
                </>
                <Notifications notificationComponent={<RateSelectedToast />} />
              </>
            ) : (
              <>
                <RateCheckPromiseHero />
                <HowItWorksRateCheckPromise />
                <RateCheckPromiseFaqs />
                <DisclaimerFooter />
              </>
            )}
          </>
        )}
      </ContentContainer>
    </ContentGrid>
  )
}

export type ArrowColor = "green" | "orange" | null

export type Arrows = {
  initialRate: ArrowColor
  monthlyPayment: ArrowColor
  overallCost: ArrowColor
  aprc: ArrowColor
  productFee: ArrowColor
  endDate: ArrowColor
}

const ToastText = styled.div`
  width: 100%;

  @media screen and ${devices.bp_sm} {
    max-width: 70%;
  }
`

const ToastButton = styled(Button)`
  width: 100%;
  background: #1a3dd1;
  color: #fff;
  text-align: center;
  box-shadow: inset 0 -6px 0px #052ea3;

  @media screen and ${devices.bp_sm} {
    width: auto;
  }
`

const RateSelectedToast = ({ id, message }: { id?: string; message?: string }) => {
  return (
    <React.Fragment>
      <ToastText>{message}</ToastText>
      <ToastButton
        onClick={() => toast.dismiss(id)}
        width="auto"
        type={2}
        text="Got it"
        style={{
          alignSelf: "end",
        }}
      />
    </React.Fragment>
  )
}

const handleEsisDownloadCurrRate = async (applicationId: string) => {
  LogGaEvent({
    action: EventAction.rateCheckEsisDownloadClicked,
    event_category: EventCategory.siteInteraction,
    event_label: "Rate check download current rate ESIS clicked",
  })

  return axios
    .get(`${process.env.MY_MOJO_API}/recommendation/${applicationId}`, {
      withCredentials: true,
    })
    .then(({ data }) => data.esisS3Key)
    .then((esisS3Key) =>
      axios.post(
        `${MY_MOJO_API}/documents/download-url`,
        {
          applicationId,
          s3Key: esisS3Key,
        },
        {
          withCredentials: true,
        }
      )
    )
    .then((response) => {
      if (response.status !== 200) throw Error("Failed to download ILR current rate ESIS document")
      LogGaEvent({
        action: EventAction.rateCheckEsisDownloadResult,
        event_category: EventCategory.customEvent,
        event_label: "Download current rate ESIS success",
      })
      window.location.assign(response.data.downloadUrl)
    })
    .catch((err) => {
      LogError(err)
    })
}

const handleEsisDownloadNewRate = async (
  applicationId: string,
  friendlyId: string,
  ilrRateId: string
) => {
  LogGaEvent({
    action: EventAction.rateCheckEsisDownloadClicked,
    event_category: EventCategory.siteInteraction,
    event_label: "Rate check download new rate ESIS clicked",
  })

  return axios
    .post(`${MY_MOJO_API}/documents/download-url`, {
      applicationId,
      s3Key: `Opportunities/${friendlyId}/rate-check/ilr/${ilrRateId}.pdf`,
    })
    .then((response) => {
      if (response.status !== 200) throw Error("Failed to download ILR new rate ESIS document")
      LogGaEvent({
        action: EventAction.rateCheckEsisDownloadResult,
        event_category: EventCategory.customEvent,
        event_label: "Download new rate ESIS success",
      })
      window.open(response.data.downloadUrl)
    })
    .catch((err) => {
      LogError(err)
    })
}

const ContentGrid = styled.div({
  display: "grid",
  gridColumnStart: 2,
  gridRowStart: 1,
  columnGap: space,
  [mobile]: {
    gridTemplateColumns: "repeat(3, 1fr)",
    padding: "1rem",
  },
  [tablet]: {
    gridTemplateColumns: "repeat(6, 1fr)",
    padding: tabletContentPadding,
  },
  [desktop]: {
    gridTemplateColumns: "repeat(12, 1fr)",
    padding: `${desktopContentPadding}px ${40}px`,
  },
})

const ContentContainer = styled.div({
  gridColumnStart: 1,
  [mobile]: {
    gridColumnEnd: "span 3",
  },
  [tablet]: {
    gridColumnEnd: "span 6",
  },
  [desktop]: {
    gridColumnStart: 2,
    gridColumnEnd: "span 10",
  },
})

const TextContainer = styled.div({
  margin: "1rem 0",
})

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  @media screen and ${devices.bp_xl} {
    width: 34em;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > p {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    color: rgba(59, 66, 88, 1);
    line-height: 24px;
  }
`

const Header = styled.h1`
  font-size: 39px;
  font-weight: 550;
  min-width: 60%;
`

const TitleContainer = styled.div({
  display: "flex",
  justifyContent: "space-between",

  [mobile]: {
    flexDirection: "column",
    justifyContent: "start",
  },
})

const Subheader = styled.h1`
  font-size: 18px;
  font-weight: 600;
`

const RatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #f1f4fe;
  padding: 16px;
  border-radius: 32px;
  gap: 16px;
`

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const SummaryProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 32px;
  flex: 5;

  @media screen and ${devices.bp_xl} {
    justify-content: end;
    align-items: end;
  }
`

const ProductDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const ProductExtrasWrapper = styled.div`
  display: flex;
  /* flex: 100%; */
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: end;
`

const SummaryIcon = styled.div({
  display: "flex",
  alignItems: "center",
  width: "70%",
  [tablet]: {
    width: "20%",
  },
  [desktop]: {
    width: "20%",
  },
})

const ProductExtras = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`

const ExtrasList = styled.ul`
  display: flex;
  border-radius: 16px;
  flex-wrap: wrap;
  padding: 0;
  gap: 8px;

  @media screen and ${devices.bp_sm} {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
  }
`

const ExtraPill = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  padding: 12px 8px;
  background: #d3ddf9;

  & > span {
    font-size: 12px;
  }
`

const SummaryCheckDateDesktop = styled.div({
  display: "flex",
  alignSelf: "top",
  ["& > span"]: {
    marginLeft: "1rem",
  },
  [tablet]: {
    display: "none",
  },
  [mobile]: {
    display: "none",
  },
})

const SummaryCheckDateMobile = styled.div({
  display: "flex",
  marginTop: "1rem",
  justifyContent: "start",
  ["& > span"]: {
    marginLeft: "1rem",
  },
  [desktop]: {
    display: "none",
  },
})

const SummaryMessage = styled.div`
  background: #eef5f3;
  border-radius: 16px;
  padding: 32px;
  border: 1px solid #d4efee;

  & > span {
    font-weight: 600;
  }
`

const AlertSummaryMessage = styled.div`
  background: #faf1ee;
  border-radius: 16px;
  padding: 32px;
  border: 1px solid #f2d0dc;

  & > span {
    font-weight: 600;
  }
`

const RatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;

  @media screen and ${devices.bp_xl} {
    flex-direction: row;
  }
`
