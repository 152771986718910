import { useCallback, useEffect, useState } from "react"

type ManifestEntry = {
  file?: string
  src?: string
  isEntry?: boolean
  isDynamicEntry?: boolean
  imports?: string[]
  dynamicImports?: string[]
  css?: string[]
}

type Manifest = Record<string, ManifestEntry>

export const usePrefetch = (path?: string) => {
  const base = window.location.protocol + "//" + window.location.host
  const [manifest, setManifest] = useState<Manifest | null>(null)
  const [isPrefetched, setIsPrefetched] = useState(false)

  useEffect(() => {
    if (!path) return

    const fetchManifest = async () => {
      try {
        const response = await fetch(`${path}/manifest.json`)
        if (!response.ok) throw new Error("Failed to fetch manifest")
        const manifestData: Manifest = await response.json()
        setManifest(manifestData)
      } catch (error) {
        console.error("Error fetching manifest:", error)
      }
    }

    fetchManifest()
  }, [path])

  const prefetchResources = useCallback(
    (url: string) => {
      if (!manifest || isPrefetched) return
      setIsPrefetched(true)

      const prefetchLink = document.createElement("link")
      prefetchLink.rel = "prefetch"
      prefetchLink.href = `${base}${url}`
      document.head.appendChild(prefetchLink)

      Object.values(manifest).forEach((entry) => {
        // Prefetch JS file
        if (entry.file) {
          const scriptLink = document.createElement("link")
          scriptLink.rel = "prefetch"
          scriptLink.href = `${base}/${entry.file}`
          scriptLink.as = "script"
          document.head.appendChild(scriptLink)
        }

        // Prefetch CSS files
        if (entry.css) {
          entry.css.forEach((cssFile) => {
            const cssLink = document.createElement("link")
            cssLink.rel = "prefetch"
            cssLink.href = `${base}/${cssFile}`
            cssLink.as = "style"
            document.head.appendChild(cssLink)
          })
        }
      })
    },
    [manifest, path, isPrefetched]
  )

  return { prefetchResources, isPrefetched }
}
