import { useState, useEffect } from "react"
import axios from "axios"

export const useRateCheck = (friendlyId: string) => {
  const [isInRateCheckExperiment, setIsInRateCheckExperiment] = useState<boolean | null>(null)

  useEffect(() => {
    if (!friendlyId) return

    axios
      .get(`${process.env.MY_MOJO_API}/rate-check-bucket/${friendlyId}`, {
        withCredentials: true,
      })
      .then(({ data }) => {
        setIsInRateCheckExperiment(data?.rateCheckPromise || false)
      })
      .catch((error) => {
        console.error("Error fetching rate check data:", error)
        setIsInRateCheckExperiment(false)
      })
  }, [friendlyId])

  return isInRateCheckExperiment
}

export default useRateCheck
