import axios from "axios"
import {
  ContentContainer as BaseContentContainer,
  ContentGrid,
  desktop,
  mobile,
} from "components/_blueprint/Layout"
import { Paragraph } from "components/_blueprint/Typography"
import { Button } from "components/atoms"
import { Loader, LoaderTheme } from "components/atoms/Loader"
import { Notifications } from "components/atoms/Notifications"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"
import * as React from "react"
import { useEffect, useState } from "react"
import toast from "react-hot-toast/headless"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import devices from "styles/devices"
import { ConveyancingFaqs } from "./FAQ"
import { FilteredQuotes, filterQuotes } from "./filterQuotes"
import { QuoteCard } from "./QuoteCard"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { useSession } from "lib/auth/react"
import { InstructionStatus } from "../types"

export const ConveyancingPage = () => {
  const { friendlyId } = useParams<{ friendlyId: string }>()
  const [quoteList, setQuoteList] = useState<FilteredQuotes>(FilteredQuotes.EMPTY)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { isLoading: sessionLoading } = useSession()

  useTrackNavigatedToPage("/application/conveyancing")

  useEffect(() => {
    if (friendlyId && !sessionLoading) {
      axios
        .get(`${process.env.MY_MOJO_API}/conveyancing/quote-list/${friendlyId}`, {
          withCredentials: true,
        })
        .then(({ data }) => {
          setQuoteList(filterQuotes(data))
          setIsError(false)
          setIsLoading(false)
        })
        .catch((error) => {
          console.error("Error:", error)
          setIsError(true)
          setIsLoading(false)
        })
    }
  }, [friendlyId, sessionLoading])

  useEffect(() => {
    if (quoteList.quotes.length == 0) {
      LogGaEvent({
        action: EventAction.buttonClicked,
        event_category: EventCategory.siteInteraction,
        event_label: "No quotes returned",
      })
    }
  }, [quoteList])

  useEffect(() => {
    if (quoteList.expired) {
      LogGaEvent({
        action: EventAction.buttonClicked,
        event_category: EventCategory.siteInteraction,
        event_label: "Quotes expired",
      })
    }
  }, [quoteList.expired])

  useEffect(() => {
    if (quoteList.isIndicative) {
      LogGaEvent({
        action: EventAction.buttonClicked,
        event_category: EventCategory.siteInteraction,
        event_label: "Quotes indicative",
      })
    }
  }, [quoteList.isIndicative])

  // A list of quotes may have one approved or instructed and many cancelled
  // so we want to decide which to show in order of importance
  const instructionStatusPrecedence = {
    INSTRUCTED: 3,
    APPROVED: 2,
    INSTRUCTION_CANCELLED: 1,
  }

  const getInstructionStatus = (): InstructionStatus | null => {
    const instructedQuotes = quoteList.quotes.filter(
      (quote) =>
        quote.instructionStatus === "INSTRUCTED" ||
        quote.instructionStatus === "APPROVED" ||
        quote.instructionStatus === "INSTRUCTION_CANCELLED"
    )

    if (instructedQuotes.length === 0) return null

    instructedQuotes.sort(
      (a, b) =>
        instructionStatusPrecedence[b.instructionStatus] -
        instructionStatusPrecedence[a.instructionStatus]
    )

    return instructedQuotes[0].instructionStatus
  }

  const instructionStatus = getInstructionStatus()

  return (
    <ContentGrid>
      <ContentContainer>
        {isLoading ? (
          <LoaderContainer>
            <Loader size={150} theme={LoaderTheme.Outline} />
          </LoaderContainer>
        ) : (
          <>
            <HeaderContainer>
              <TitleContainer>
                <Header>Conveyancing</Header>
              </TitleContainer>
              {isError || quoteList.quotes.length == 0 ? (
                <TextContainer>
                  <SubHeader>
                    If you’re buying a property or remortgaging with a new lender, then we can help
                    you find solicitors for conveyancing.
                  </SubHeader>
                  <p>
                    Get in touch with your Mortgage Expert, who will be able to provide you with a
                    number of options from our panel of solicitors.
                  </p>
                </TextContainer>
              ) : (
                <TextContainer>
                  <p>
                    Easily compare conveyancing quotes, track your case progress, and get real-time
                    updates - all in one place. Whether you’re buying, selling, or remortgaging,
                    we’ll keep you informed every step of the way.
                  </p>
                </TextContainer>
              )}
              {instructionStatus && (
                <StatusContainer>
                  <SubHeader>Current instruction</SubHeader>
                  <StatusRow>{renderInstructionStatus(instructionStatus)}</StatusRow>
                </StatusContainer>
              )}
            </HeaderContainer>
            {quoteList.quotes.length != 0 && (
              <>
                <QuotesContainer>
                  {quoteList.expired && (
                    <ExpiredBanner>
                      These conveyancing quotes have expired. If you would like an up to date list
                      of quotes, please contact your Mortgage Expert
                    </ExpiredBanner>
                  )}
                  {quoteList.isIndicative && (
                    <IndicativeBanner>
                      As you do not have an offer accepted on a property, we generated these quotes
                      using a generic post code in the country you are buying in. As such,{" "}
                      <b>prices quoted here are indicative</b>, and may change based on the property
                      you eventually purchase.{" "}
                    </IndicativeBanner>
                  )}
                  {quoteList.quotes.map((quote, idx) => (
                    <QuoteCard
                      quote={quote}
                      expirationDate={quoteList.expirationDate}
                      isIndicative={quoteList.isIndicative}
                      key={idx}
                      idx={idx}
                    />
                  ))}
                </QuotesContainer>
                <DisclaimerFooter />
              </>
            )}
            <ConveyancingFaqs />
            <Notifications notificationComponent={<RateSelectedToast />} />
          </>
        )}
      </ContentContainer>
    </ContentGrid>
  )
}
const ToastText = styled.div`
  width: 100%;

  @media screen and ${devices.bp_sm} {
    max-width: 70%;
  }
`

const ToastButton = styled(Button)`
  width: 100%;
  background: #1a3dd1;
  color: #fff;
  text-align: center;
  box-shadow: inset 0 -6px 0px #052ea3;

  @media screen and ${devices.bp_sm} {
    width: auto;
  }
`

const RateSelectedToast = ({ id, message }: { id?: string; message?: string }) => {
  return (
    <React.Fragment>
      <ToastText>{message}</ToastText>
      <ToastButton
        onClick={() => toast.dismiss(id)}
        width="auto"
        type={2}
        text="Got it"
        style={{
          alignSelf: "end",
        }}
      />
    </React.Fragment>
  )
}

const ContentContainer = styled(BaseContentContainer)({
  [desktop]: {
    gridColumnStart: 2,
    gridColumnEnd: "span 9",
  },
})

const TextContainer = styled.div({
  maxWidth: "584px",
})

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  @media screen and ${devices.bp_xl} {
    width: 34em;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > p {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    color: rgba(59, 66, 88, 1);
    line-height: 24px;
  }
`

const Header = styled.h1`
  font-size: 39px;
  font-weight: 550;
  min-width: 60%;
`

const TitleContainer = styled.div({
  display: "flex",
  justifyContent: "space-between",

  [mobile]: {
    flexDirection: "column",
    justifyContent: "start",
  },
})

const SubHeader = styled.h1`
  font-size: 18px;
  font-weight: 600;
`

const QuotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  margin-bottom: 24px;
`

const IndicativeBanner = styled.div`
  background: #fcc778;
  padding: 24px;
  border-radius: 8px;
`

const ExpiredBanner = styled.div`
  padding: 16px;
  gap: 8px;
  border-radius: 16px;
  border: 1px;
  color: #6d1b22;
  background: #fcf1ee;
  border: 1px solid #d0070d;
  font-weight: 600;
  font-size: 12px;
  line-height: 2em;
`

const StatusContainer = styled(TextContainer)`
  margin-top: 16px;
`

const StatusRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 2rem 0 2rem 0;
`

const StatusText = styled.p`
  margin: 0;
  line-height: 1.5;
`

export const DisclaimerFooter = () => (
  <DisclaimerContainer>
    <Disclaimer size="caption">
      Mojo do not offer advice on which conveyancer to use, we act as an introducer to Smoove
      Conveyancing Services only.
    </Disclaimer>
    <Disclaimer size="caption">
      Smoove are responsible for vetting and approving their panel of qualified and licensed
      solicitors, please review the quotes carefully before committing to a preferred option. All
      enquiries post-instruction will need to be taken up with the chosen conveyancer directly
    </Disclaimer>
  </DisclaimerContainer>
)

const DisclaimerContainer = styled.div`
  margin-bottom: 72px;
`

const Disclaimer = styled(Paragraph)({
  textAlign: "left",
})

const renderInstructionStatus = (status: InstructionStatus) => {
  switch (status) {
    case "APPROVED":
      return (
        <>
          <IconContainer>
            <TickIcon />
          </IconContainer>
          <StatusText>
            <strong>Confirmed:</strong> You’re happy for us to proceed with this solicitor
          </StatusText>
        </>
      )
    case "INSTRUCTION_CANCELLED":
      return (
        <>
          <IconContainer>
            <CrossIcon />
          </IconContainer>
          <StatusText>
            <strong>Instruction cancelled:</strong> Conveyancing with this solicitor has been
            cancelled and won’t be instructed
          </StatusText>
        </>
      )
    case "INSTRUCTED":
      return (
        <>
          <IconContainer>
            <TickIcon />
          </IconContainer>
          <StatusText>
            <strong>Instructed:</strong> Now that you’re case has been submitted, we have sent
            instruction to the solicitors to begin conveyancing
          </StatusText>
        </>
      )
    default:
      return null
  }
}

const IconContainer = styled.div({
  minWidth: "32px",
  minHeight: "32px",
  width: "32px",
  height: "32px",
  flexShrink: "0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})

const TickIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#038352" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7256 11.6888L13.5161 22.4538L8.27441 16.9269L9.72558 15.5506L13.5161 19.5474L22.2744 10.3125L23.7256 11.6888Z"
      fill="#EDF5F3"
    />
  </svg>
)

const CrossIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#D0070D" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 17.4143L20.7929 22.2072L22.2071 20.793L17.4142 16.0001L22.2071 11.2072L20.7929 9.79297L16 14.5859L11.2071 9.79297L9.79291 11.2072L14.5858 16.0001L9.79291 20.793L11.2071 22.2072L16 17.4143Z"
      fill="#FCF1EE"
    />
  </svg>
)
