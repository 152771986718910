import React from "react"
import { usePrefetch } from "hooks/usePrefetch"
import { useEffect } from "react"
import styled from "styled-components"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory } from "models/GoogleAnalytics"

const ListItem = styled.li<{ isActive: boolean }>`
  background: ${({ isActive }) => (isActive ? "#F5F7FB" : "white")};
  margin: 0 -32px;

  cursor: pointer;

  :hover {
    background: ${({ isActive }) => (!isActive ? "rgba(245, 247, 251, 0.5)" : "#F5F7FB")};
  }
`

const Border = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e9edf5;
  margin: 0 32px;
`

const BulletPoint = styled.div<{ active: boolean }>`
  border-radius: 50%;
  height: 1.5em;
  width: 1.5em;
  border: 8px solid ${({ active }) => (active ? "#073ed9" : "#c1c5d1")};
  margin-right: 18px;
`

interface NavButtonProps {
  /**
   * The path you want to navigate to
   */
  path: string
  /**
   * The path the JS and CSS assets are located. We use this for prfetching
   * which greatly increases the speed of navigation.
   *
   * If it's not provided, no prefetching will be done.
   */
  resourcePath?: string
  /**
   * The text of the button
   */
  label: string
  /**
   * If the button is active
   */
  isActive: boolean
}

export const BaseButton = ({ path, label, resourcePath, isActive }: NavButtonProps) => {
  const { prefetchResources } = usePrefetch(resourcePath)

  useEffect(() => {
    prefetchResources(path)
  }, [])

  return (
    <ListItem
      isActive={isActive}
      onClick={() => {
        LogGaEvent({
          action: EventAction.navigation,
          event_category: EventCategory.functionalInteraction,
          event_label: label,
        })
        window.location.href = path
      }}
    >
      <Border>
        <BulletPoint active={isActive} />
        <p>{label}</p>
      </Border>
    </ListItem>
  )
}
