import React from "react"
import { ContentArea } from "templates/ContentArea"
import { Heading, Paragraph } from "components/_blueprint/Typography"
import Dog from "assets/images/dog.svg"
import styled from "styled-components"
import { desktop, mobile, tablet } from "components/_blueprint/Layout"
import { RateCheckPromiseFaqs } from "./FAQs"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"
import { Button, ButtonType } from "components/atoms"
import { useHistory } from "react-router-dom"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"

export const OptedOut = () => {
  useTrackNavigatedToPage("/rate-check-promise-opted-out")
  return (
    <ContentArea maxWidth={1200}>
      <RateCheckPromiseHero />
      {/* <HowItWorksRateCheckPromise /> */}
      <RateCheckPromiseFaqs />
      <DisclaimerFooter />
    </ContentArea>
  )
}

export const RateCheckPromiseHero = () => {
  const history = useHistory();
  return (
    <>
      <PromiseContainer style={{ display: "flex" }}>
        <PromiseText>
          <Heading>Rate Check Promise</Heading>
          <Paragraph>
            <b>We can't automatically track rates for your deal - but we're still here to help!</b>
          </Paragraph>
          <Paragraph>
            Our Rate Check Promise is designed to keep an eye on the interest rate of your recommended deal and let you know if it drops.
          </Paragraph>
          <Paragraph>However, we're still developing this technology and there are certain types of deals we can't automatically check, unfortunately this includes yours.</Paragraph>
          <Paragraph>That doesn't mean you'll miss out! If you'd like us to manually check for a better rate, just let us know via the form below.</Paragraph>
          <Button type={ButtonType.Outline} width={"250px"} text={"Request rate check"} onClick={() => {
            LogGaEvent({
              action: EventAction.buttonClicked,
              event_category: EventCategory.functionalInteraction,
              event_label: EventLabel.requestRateCheck,
            })
            history.push("/rate-check-request")
          }} />
        </PromiseText>
        <PromiseImageContainer>
          <PromiseImage src={Dog} alt="Dog" />
        </PromiseImageContainer>
      </PromiseContainer>
    </>
  )
}

export const DisclaimerFooter = () => (
  <>
    <Disclaimer size="caption">
      You can read about our Rate Check Promise terms and conditions within our{" "}
      <ExternalLink
        title="Terms of Advisory Services"
        target="_blank"
        href="https://hosted-assets.mojomortgages.com/documents/Terms%20of%20our%20Advisory%20Services.pdf"
      >
        Terms of Advisory Service.
      </ExternalLink>
    </Disclaimer>
    <Disclaimer size="caption">
      Mojo is a trading style of Life's Great Limited which is registered in England and Wales
      (06246376). We are authorised and regulated by the Financial Conduct Authority and are on the
      Financial Services Register (478215)
    </Disclaimer>
    <Disclaimer size="caption">
      Our registered office is The Cooperage, 5 Copper Row, London, SE1 2LH,{" "}
    </Disclaimer>
    <Disclaimer size="caption">To contact us by phone, please call 0333 123 0012.</Disclaimer>
    <Disclaimer size="caption">
      YOUR HOME/PROPERTY MAY BE REPOSSESSED IF YOU DO NOT KEEP UP REPAYMENTS ON YOUR MORTGAGE
    </Disclaimer>
  </>
)

const ExternalLink = styled.a({
  textDecoration: "none",
  color: "blue",
})

const Disclaimer = styled(Paragraph)({
  textAlign: "center",
})

const PromiseContainer = styled.div({
  display: "flex",
  [mobile]: {
    flexDirection: "column",
  },
  [tablet]: {
    flexDirection: "column",
  },
  [desktop]: {
    flexDirection: "row",
  },
})

const PromiseText = styled.div({
  [mobile]: {},
  [tablet]: {},
  [desktop]: {
    flex: 7,
  },
})

const PromiseImageContainer = styled.div({
  [mobile]: {
    textAlign: "center",
    padding: "2rem",
  },
  [tablet]: {
    textAlign: "center",
    padding: "2rem",
  },
  [desktop]: {
    flex: 1,
    width: 250,
  },
})

const PromiseImage = styled.img({
  [mobile]: {
    // shrink image if there's not enough width
    maxWidth: "100%",
    height: "auto",
  },
  [tablet]: {
    // shrink image if there's not enough width
    maxWidth: "100%",
    height: "auto",
  },
  [desktop]: {},
})
